<template>
    <el-drawer
        :title="'Tag'"
        :withHeader="false"
        :model-value="photoStore.open"
        :before-close="handleClose"
        @open="onOpenDrawer()"
        :direction="direction"
        class="photo-tag-drawer"
    >
        <div v-if="!loading">
            <div class="header">
                <div class="title">
                    <h4 class="blue-text">Taggen</h4>
                    <p class="info-popup-trigger" @click="showInfoDialog = true">Hoe werkt het?</p>
                    <span class="close-drawer-button" @click="handleClose"><i class="far fa-times"></i></span>
                </div>
                <div class="d-none d-md-block">
                    <p class="confirmed-tags-title"><b>Tags in deze foto ({{ confirmedTags.length }}):</b> <span v-if="confirmedTags.length == 0">Geen tags voor deze foto.</span></p>
                    <div class="confirmed" v-if="tagsVisible && confirmedTags.length > 0">
                        <div class="item" v-for="tag in confirmedTags">
                            <el-popconfirm
                                confirm-button-text="Ja"
                                cancel-button-text="Annuleer"
                                title="Wil je deze tag rapporteren als onjuist?"
                                @confirm="reportTag(tag.id)"
                                :teleported="false"
                            >
                                <template #reference>
                                    <i class="fas fa-circle-exclamation report-tag-trigger"></i>
                                </template>
                            </el-popconfirm>
                            <img class="profile-picture" :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/minified/' + tag.tagged_user.profile_pic" v-if="tag.tagged_user.profile_pic">
                            <img class="profile-picture" src="/images/profile-pic/profile-person.jpg" v-else>
                            <p class="name">{{ tag.tagged_user.name }}</p>
                        </div>
                    </div>
                    <div class="hidden-tags" v-else-if="confirmedTags.length > 0" @click="showTags">
                        <span class="overlay"><i class="far fa-eye"></i> Bekijk tags</span>
                        <div class="confirmed">
                            <div class="item" v-for="i in confirmedTags.length" :key="i">
                                <img class="profile-picture" src="/images/profile-pic/profile-person.jpg">
                                <p class="name">Voornaam Achternaam</p>
                            </div>
                        </div>
                    </div>
                </div>
                <el-collapse v-model="activeCollapse" accordion class="d-md-none collapse-tags-information">
                    <el-collapse-item name="1">
                        <p>
                            <b>Tags in deze foto ({{ confirmedTags.length }}): </b>
                            <span v-for="(tag, index) in confirmedTags" :key="index" v-if="confirmedTags.length > 0 && tagsVisible">
                                <el-popconfirm
                                    confirm-button-text="Ja"
                                    cancel-button-text="Annuleer"
                                    :title="'Wil je deze tag ('+ tag.tagged_user.name +') rapporteren als onjuist?'"
                                    @confirm="reportTag(tag.id)"
                                    :teleported="false"
                                    v-if="tagsVisible"
                                >
                                    <template #reference>
                                        <span>{{ tag.tagged_user.name }}</span>
                                    </template>
                                </el-popconfirm>
                                <span v-if="index < confirmedTags.length - 1">, </span>
                            </span>
                            <span v-else-if="confirmedTags.length > 0" @click="showTags">
                                <span class="text-decoration-underline"><i class="far fa-eye"></i> Bekijk tags</span>
                            </span>
                            <span v-else>Geen tags voor deze foto.</span>
                        </p>

                        <p class="mb-0">
                            <b>Jouw tags ({{ userTags.length }}): </b>
                            <span v-if="userTags.length > 0">
                                {{ userTags.map(user => user.tagged_user.name).join(', ') }}
                            </span>
                            <span v-else>Jij hebt geen tags aan deze foto toegevoegd.</span>
                        </p>
                    </el-collapse-item>
                </el-collapse>

                <p class="mb-0 d-none d-md-block">
                    <b>Jouw tags ({{ userTags.length }}): </b>
                    <span v-if="userTags.length > 0">
                        {{ userTags.map(user => user.tagged_user.name).join(', ') }}
                    </span>
                    <span v-else>Jij hebt geen tags aan deze foto toegevoegd.</span>
                </p>
            </div>

            <div class="members-list" v-if="!tagsVisible">
                <h5 class="blue-text">Leden</h5>
                <el-input type="text" v-model="nameFilter" placeholder="Zoek op naam" @input="textFilterChanged" @focus="hideContentMobile"></el-input>
                <div class="item" v-if="members.length == 0">
                    <p>Geen leden gevonden</p>
                </div>
                <div class="item" v-for="member in members" v-else>
                    <div class="profile-picture">
                        <img :src="'https://voxvivarotterdam.s3.eu-central-1.amazonaws.com/profile-pictures/minified/' + member.profile_pic" v-if="member.profile_pic">
                        <img src="/images/profile-pic/profile-person.jpg" v-else>
                    </div>
                    <p class="name">{{member.name}}</p>
                    <el-button class="btn btn-blue" @click="addTag(member.id)" v-if="!userTagsIds.includes(member.id)"><i class="fas fa-plus"></i></el-button>
                    <el-button class="btn btn-delete" type="danger" @click="removeTag(member.id)" v-if="userTagsIds.includes(member.id) && !declinedTagsIds.includes(member.id)"><i class="fas fa-times"></i></el-button>
                    <el-button class="btn btn-declined" type="warning" v-if="declinedTagsIds.includes(member.id)"><i class="fas fa-warning"></i></el-button>
                </div>
            </div>
        </div>
        <div v-else>
            <el-skeleton :loading="true" :rows="10"></el-skeleton>
        </div>

        <el-dialog
            :model-value="showInfoDialog"
            title="Uitleg foto's taggen"
            :before-close="() => showInfoDialog = false"
            width="95%"
        >
            <template #default>
                <h5 class="blue-text">Waarom moet ik dit doen?</h5>
                <p>Allereerst is dit de perfecte manier om de namen van mensen te leren kennen. Daarnaast kan iedereen foto's waar hij of zij op staat, makkelijk terugvinden bij zijn account. En uiteraard is er ook een achievement aan verbonden!</p>
                
                <h5 class="blue-text">Hoe werkt het?</h5>
                <p>Het is goed om een aantal dingen te weten:</p>
                <ul class="disc ml-0">
                    <li>Eenmaal de tags van anderen bekeken bij een foto? Dan kan jij bij die foto niet meer taggen.</li>
                    <li>Tag alleen de mensen die als onderwerp op de foto staan. Dit is subjectief, maar zal meestal voor zich spreken. Bij groepsfoto's ben je gewoon wat langer bezig 😉</li>
                    <!-- <li>Taggen is alleen mogelijk in de {{ props.config?.tagging_disable_threshold }} dagen nadat een fotoalbum op de website is geplaatst.</li> -->
                    <li>Een tag is direct bevestigd. Klopt een tag niet? Dan moeten {{ props.config?.report_threshold }} leden een tag als ongeldig verklaren (door op <span class="d-none d-md-inline">het gele uitroepteken</span><span class="d-inline d-md-none">een naam</span> bij 'tags in deze foto' te klikken) waarna deze wordt weggehaald. Hierna kan deze tag ook niet meer worden toegevoegd of verwijderd <i>(oranje knop)</i>.</li>
                    <li><strong class="blue-text">Als laatste:</strong> let op je databundel als je op je telefoon foto's bekijkt!</li>
                </ul>

                <button class="btn btn-blue mt-4" @click="showInfoDialog = false">Ik snap het. Aan de slag!</button>
            </template>
        </el-dialog>
    </el-drawer>
</template>

<script setup lang="ts">
import { computed, h, ref } from 'vue'
import type { PropType, Ref } from 'vue'
import { ElCheckbox, ElMessageBox, type DrawerProps } from 'element-plus'
import { usePhotoStore } from '../../stores/photo'
import { useUserStore } from '../../stores/user'
import http from "../../libs/axios"
import _ from 'lodash'

interface Member {
    id: number,
    name: string,
    profile_pic: string,
    birthdate: number,
    phone: string
}

interface ConfigPhotos {
    report_threshold: string,
    tagging_disable_threshold: string,
}

const props = defineProps({
    'config': Object as PropType<ConfigPhotos>
})
const photoStore = usePhotoStore()
const userStore = useUserStore()
const members: Ref<(Array<Member>)> = ref([])
const confirmedTags: Ref<(Array<Member>)> = ref([])
const declinedTags: Ref<(Array<Member>)> = ref([])
const userTags: Ref<(Array<Number>)> = ref([])
const nameFilter: Ref<String> = ref('')
const loading: Ref<Boolean> = ref(false)
const direction = ref<DrawerProps['direction']>('rtl')
const showInfoDialog: Ref<Boolean> = ref(false)
const activeCollapse = ref('1')
const tagsVisible = ref(false)

// If window size is less than 768px, change direction to 'bottom'. listen for change but also set initial value
if (window.innerWidth < 768) {
    direction.value = 'btt'
}
window.addEventListener('resize', () => {
    if (window.innerWidth < 768) {
        direction.value = 'btt'
    } else {
        direction.value = 'rtl'
    }
})

function onOpenDrawer() {
    loading.value = true
    tagsVisible.value = false
    loadMembers()
    getTags()
}

const declinedTagsIds = computed(() => {
    let ids: Array<Number> = declinedTags.value.map(item => {return item.tagged_user.id})
    
    return ids
})

const userTagsIds = computed(() => {
    let ids: Array<Number> = userTags.value.map(item => {return item.tagged_user.id})
    
    return ids
})

const textFilterChanged = _.debounce(() => {
    loadMembers()
}, 500);

function loadMembers() {
    http.post('/api/leden/all', {
        filters: {'name': nameFilter.value},
        perPage: 500
    })
    .then(response => {
        members.value = response.members
    })
    .catch(() => {return false})
}

function getTags() {
    http.get('/api/fotos/' + photoStore.selected_photo_id + '/tags')
    .then(response => {
        confirmedTags.value = response.confirmed_tags,
        declinedTags.value = response.declined_tags,
        userTags.value = response.user_tags,
        tagsVisible.value = response.has_showed_tags,
        loading.value = false
    })
    .catch(() => {return false})
}

function addTag(member_id: Number) {
    http.post('/api/fotos/tag', {
        photo_id: photoStore.selected_photo_id,
        tagged_user_id: member_id
    })
    .then(() => {
        getTags()

        ElNotification({
            title: 'Getagd!',
            message: 'Tag succesvol toegevoegd. Dankjewel!',
            type: 'success',
            position: 'top-left',
            zIndex: 999999
        })
    })
    .catch(() => {return false})
}

function removeTag(member_id: Number) {
    http.delete('/api/fotos/tag/'+photoStore.selected_photo_id+'/'+member_id)
    .then(() => {
        getTags()

        ElNotification({
            title: 'Verwijderd!',
            message: 'Tag succesvol verwijderd.',
            type: 'success',
            position: 'top-left',
            zIndex: 999999
        })
    })
    .catch(() => {return false})
}

function reportTag(tag_id: Number) {
    http.post('/api/fotos/tag/report', {
        photo_tag_id: tag_id
    })
    .then(() => {
        getTags()

        ElNotification({
            title: 'Gerapporteerd!',
            message: 'Tag succesvol gerapporteerd.',
            type: 'success',
            position: 'top-left',
            zIndex: 999999
        })
    })
    .catch(() => {return false})
}

function showTags() {
    if (!userStore.preferences || userStore.preferences.showtagsmodal) {
        const checked = ref<boolean | string | number>(false)

        ElMessageBox({
            title: 'Tags bekijken',
            message: () =>
                h('p', null, [
                    h('p', null, 'Als je de tags wilt zien kan jij voor deze foto geen tags meer toevoegen of verwijderen. Doorgaan?'),
                    h(ElCheckbox, {
                        style: 'margin-top: 1rem; margin-right: .5rem;',
                        id: 'showtagsmodal',
                        modelValue: checked.value,
                        'onUpdate:modelValue': (val: boolean | string | number) => {
                            checked.value = val
                        }
                    }),
                    h('label', { style: 'cursor: pointer;', for: 'showtagsmodal' }, 'Melding niet meer tonen'),
                ]),
            appendTo: '.photo-tag-drawer',
            confirmButtonText: 'Weergeven',
            cancelButtonText: 'Annuleren',
            type: 'warning'
        })
        .then(() => {
            if (checked.value) {
                userStore.updatePreference({
                    identifier: 'showtagsmodal',
                    preference: false
                })
            }
            showTagsAction()
        })
        .catch(() => {});
    } else {
        showTagsAction()
    }
}

function showTagsAction() {
    http.post('/api/fotos/tag/show', {
        photo_id: photoStore.selected_photo_id
    })
    .then(() => {
        tagsVisible.value = true
    })
    .catch(() => {return false})
}

function hideContentMobile() {
    if (window.innerWidth < 768) {
        // Add d-none class to header
        activeCollapse.value = ''
    }
}

function handleClose () {
    loading.value = true
    photoStore.toggleDrawer(false)
}
</script>