<template>
    <div class="content" v-if="event">
        <div class="event-info">
            <div class="info-row">
                <span class="icon"><i class="fa-regular fa-coins"></i></span>
                <span class="text">{{ (event.costs == 0 ? 'Gratis' : (registered ? formatCurrency(amountToPay) :
        formatCurrency(event.costs))) }}</span>
            </div>
            <div class="info-row">
                <span class="icon"><i class="fa-regular fa-people-pulling"></i></span>
                <span class="text">{{ registrations_count }} {{ (registrations_count == 1 ? 'aanmelding' :
        'aanmeldingen') }}</span>
            </div>
        </div>
        <template v-if="register_status === ''">
            <el-skeleton >
                <template #template>
                    <el-skeleton-item variant="text" style="width: 150px; height: 33px; margin-top: .5rem;"/>
                </template>
            </el-skeleton>
        </template>
        <template v-if="register_status === 'closed' || register_status === 'done'">
            <button class="btn btn-white" role="button" disabled>{{ button_text }}</button>
        </template>

        <template v-if="register_status === 'open' || register_status === 'open_guest'">
            <button class="btn btn-white" role="button" @click="openRegisterModal = true">{{ button_text }}</button>
        </template>

        <template v-if="register_status === 'login'">
            <button class="btn btn-white" role="button" @click="clickLoginLink">{{ button_text }}</button>
        </template>

        <template v-if="register_status === 'payment_due'">
            <button class="btn btn-white" role="button" @click="openPaymentModal = true">{{ button_text }}</button>
        </template>

        <template v-if="register_status !== 'closed' && event.register_closed >= moment().unix()">
            <p class="register-open-till">Inschrijving sluit {{ formatUnixDate(event.register_closed, 'DD-MM, H:mm') }}</p>
        </template>
        <RegisterModal :event="event" :user-logged-in="user_logged_in" v-model:dialogRegister="openRegisterModal"
            @guest-registered="handleGuestRegistered" @user-registered="getRegistrationsCount"
            @update:dialogRegister="handleRegisterModalUpdate"></RegisterModal>
        <PaymentModal :amount-to-pay="amountToPay" :wallet-enabled="walletEnabled" :user-balance="userBalance" :transaction-costs="transactionCosts"
            @execute-payment="payEvent" :payment-description="`De kosten voor ${event.name} zijn &euro;${amountToPay}`"
            v-model:dialogPayment="openPaymentModal" v-if="register_status === 'payment_due'"></PaymentModal>
    </div>
</template>

<script setup lang="ts">
type MaybeInterval = ReturnType<typeof setInterval> | undefined

import { onBeforeUnmount, computed, ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import { useEventStore } from '../../stores/event'
//@ts-ignore
import moment from 'moment/min/moment-with-locales'
import PaymentModal from './../PaymentModal.vue'
import RegisterModal from './RegisterModal.vue'
import http from "../../libs/axios"
import { ElNotification } from 'element-plus'
import { formatUnixDate } from "@F/js/helpers.ts"

const props = defineProps({
    'event': Object,
    'user_id': String,
    'mollieRedirect': String,
    'userBalance': Number,
    'transactionCosts': Number,
})
const eventStore = useEventStore()

const button_text: Ref<string> = ref("Inschrijving gesloten")
const register_status: Ref<string> = ref('')
let polling: MaybeInterval = undefined
let polling_registrations: MaybeInterval = undefined
const openRegisterModal = ref(false)
const openPaymentModal = ref(false)
const registrations_count = ref(0)
const registered = ref(false)
const registrationId = ref(0)
const amountToPay = ref(0)
const showPopup = ref(false)
const checkPayment = ref(true)
const walletEnabled = ref(false)

const user_logged_in = computed(() => {
    return (props.user_id != '' ? true : false)
})

onMounted(() => {
    enablePolling()

    if (props.mollieRedirect && props.mollieRedirect == 'true') {
        showPopup.value = true
        history.replaceState({}, document.title, window.location.href.split('?')[0])
    }
})

onBeforeUnmount(() => {
    clearInterval(polling)
    clearInterval(polling_registrations)
})


function payEvent(paymentMethod: string) {
    http.post('/api/agenda/betalen',
        {
            paymentMethod: paymentMethod,
            registration_id: registrationId.value,
            event_id: props.event?.id,
        })
        .then((checkoutUrl) => {
            if (typeof checkoutUrl === 'string') {
                window.location.assign(checkoutUrl)
            }
        })
        .catch(() => { return false })
}

function getRegistrationsCount() {
    http.get('/api/agenda/registratie-data/' + props.event?.id)
        .then(response => {
            registrations_count.value = response.registrations_count

            if (response.registered) {
                registered.value = true
                registrationId.value = response.registration.id
                amountToPay.value = response.registration.amount_to_pay
                walletEnabled.value = response.walletEnabled

                if (polling) {
                    clearInterval(polling)
                }

                if (checkPayment.value)
                    checkIfPaid()
            }
        })
        .catch(error => {
            console.log(error)
        });
}

function enablePolling() {
    if (!props.event)
        return false

    if (props.event.register_closed && moment().unix() < props.event.register_closed) {
        if (!polling) {
            polling = setInterval(() => {
                checkIfOpenButton()
            }, 1000)
        }

        if (moment().unix() >= props.event.register_open) {
            getRegistrationsCount()

            if (!polling_registrations) {
                polling_registrations = setInterval(() => {
                    getRegistrationsCount()
                }, 20000)
            }
        }
    } else {
        button_text.value = "Inschrijving al gesloten"
        register_status.value = 'closed'

        getRegistrationsCount()
    }
}

function checkIfOpenButton() {
    if (!props.event)
        return false

    if (props.event.register_closed && moment().unix() >= props.event.register_closed) {
        button_text.value = "Inschrijving al gesloten"
        register_status.value = 'closed'

        if (polling)
            clearInterval(polling)
        if (polling_registrations)
            clearInterval(polling_registrations)
    } else {
        if (moment().unix() >= props.event.register_open) {
            if (!user_logged_in.value) {
                if (!props.event.open_guests) {
                    button_text.value = "Login om in te schrijven"
                    register_status.value = 'login'
                } else {
                    button_text.value = "Inschrijven gast"
                    register_status.value = 'open_guest'
                }
            } else {
                button_text.value = "Inschrijven"
                register_status.value = 'open'

                if (props.event.max_registrations) {
                    if (registrations_count.value < props.event.max_registrations) {
                        button_text.value = "Inschrijven (max. " + props.event.max_registrations + ")"
                    } else {
                        button_text.value = "Inschrijving vol"
                        register_status.value = 'closed'
                    }
                }
            }
        } else {
            if (!props.event.open_guests && !user_logged_in) {
                button_text.value = "Login om in te schrijven"
                register_status.value = 'login'
            } else {
                button_text.value = tillRegisterOpenText()
                register_status.value = 'closed'
            }
        }
    }
}

const checkIfPaid = async () => {
    await eventStore.getPaymentsDue()

    if (eventStore.events_to_pay.includes(props.event?.id)) {
        button_text.value = "Betalen"
        register_status.value = 'payment_due'

        if (showPopup.value) {
            ElNotification({
                title: 'Oeps',
                message: 'Er ging iets fout met het betalen.',
                type: 'error'
            })
        }
    } else {
        button_text.value = "Je bent al ingeschreven"
        register_status.value = 'done'

        if (showPopup.value) {
            ElNotification({
                title: 'Betaald',
                message: 'Betaling succesvol. Dankjewel!',
                type: 'success'
            })
        }

        checkPayment.value = false
    }

    showPopup.value = false
}

function handleGuestRegistered() {
    clearInterval(polling)
    getRegistrationsCount()

    button_text.value = "Je bent al ingeschreven"
    register_status.value = 'done'
}

const handleRegisterModalUpdate = async (value: boolean) => {
    if (!value && user_logged_in.value) {
        await checkIfPaid()
        if (register_status.value === 'payment_due') {
            openPaymentModal.value = true;
        }
    }
};

function clickLoginLink() {
    const loginElement = document.getElementsByClassName('login-link')[0]

    if (loginElement)
        loginElement.click()
}

function tillRegisterOpenText() {
    let duration_left = moment.duration(moment(props.event?.register_open * 1000).diff(moment()))
    let seconds_left = duration_left.asSeconds()

    if (seconds_left < 60) {
        return "Inschrijving open in " + Math.ceil(seconds_left) + " seconden"
    }

    return "Inschrijving open in " + duration_left.locale('nl').humanize()
}

function formatCurrency(amount: number) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount)
}



</script>
