<template>
    <a href="javascript:void(0)" rel="nofollow" @click="logout">Uitloggen</a>
</template>

<script setup lang="ts">
import http from "../libs/axios"
import { useUserStore } from '../stores/user'

const userStore = useUserStore()

function logout() {
    http.get('/api/auth/logout')
    .then(() => {
        userStore.resetPreferences()
        // Reload page after succesful logout
        window.location.reload();
    })
}
</script>