<template>
    <div>
    <el-drawer
        :title="'Winkelmandje ('+cartStore.item_count+')'"
        :withHeader="true"
        :model-value="cartStore.open"
        :before-close="handleClose"
        direction="rtl"
        class="cart-drawer"
    >
        <div class="items">
            <template v-if="cartStore.item_count > 0">
                <div class="item" v-for="(item, index) in cartStore.items" v-bind:key="index">
                    <div class="thumbnail">
                        <img :src="item.thumbnail" :alt="'Afbeelding ' + item.name">
                    </div>
                    <div class="content">
                        <div class="item-info">
                            <div>
                                <h5>{{item.name}}</h5>
                                <ul>
                                    <li v-for="option in item.options" v-bind:key="option.id">
                                        <span class="label">{{option.name}}: </span>
                                        <span class="value">{{option.selected_value.name}}</span>
                                    </li>
                                </ul>
                            </div>

                            <p>{{formatCurrency(item.price)}}</p>
                        </div>
                        <div class="item-footer">
                            <p class="stock"><i class="fa-solid fa-check"></i>Op voorraad</p>
                            <a href="javascript:void(0)" @click="removeItem(index)">Verwijderen</a>
                        </div>
                    </div>
                </div>
            </template>
            <p v-else style="margin-top: 1.5rem">Geen producten in winkelmandje</p>
        </div>
        <div class="cart-footer">
            <div class="discount-code" v-if="cartStore.item_count > 0">
                <p>Kortingscode</p>
                <div class="code-input">
                    <el-input v-maska:[codeMaskInputOptions] v-model="discountCode"></el-input>
                    <el-button class="btn btn-white" @click="applyDiscount()">Toepassen</el-button>
                </div>
                <span class="remark delete-discount" v-if="cartStore.discount_price > 0" @click="deleteDiscount()"><i class="fa-solid fa-xmark"></i> Kortingscode verwijderen</span>
            </div>
            <div class="price">
                <div class="label">
                    <p v-if="cartStore.discount_price > 0">Korting</p>
                    <p>Totaal</p>
                </div>
                <div class="value">
                    <p v-if="cartStore.discount_price > 0">{{formatCurrency(cartStore.discount_price)}}</p>
                    <p v-if="cartStore.discount_price > 0">{{formatCurrency(Math.max(0, cartStore.total_price - cartStore.discount_price))}}</p>
                    <p :class="{discounted: cartStore.discount_price}">{{formatCurrency(cartStore.total_price)}}</p>
                </div>
            </div>
            <p class="remark" v-if="cartStore.item_count > 0">Voorraad wordt nogmaals gecontroleerd bij afrekenen.</p>
            <el-button class="btn btn-blue btn-checkout" type="primary" v-if="cartStore.item_count > 0" @click="openPaymentModal = true" :disabled="loadingCheckout">Afrekenen</el-button>
            <div class="continue-shopping">
                <p>
                    <span v-if="cartStore.item_count > 0">of </span>
                    <a href="javascript:void(0)" @click="handleClose()">Verder Winkelen<span aria-hidden="true"> →</span></a>
                </p>
            </div>
        </div>
    </el-drawer>
    <PaymentModal :wallet-enabled="walletEnabled" :user-balance="userBalance" :transaction-costs="transactionCosts" @execute-payment="goToCheckout" payment-description="Betaal nu je bestelling, met je saldo of met iDeal." 
         v-model:dialogPayment="openPaymentModal" ></PaymentModal>
    <el-dialog
        v-model="showSuccessPayment"
        title="Bedankt voor jouw bestelling!"
        width="400px"
        :center="true"
        :append-to-body="true"
    >
        <div class="text-center">Je bestelling is opgeslagen. Kijk op <a href="/account">jouw account pagina</a> voor de status van jouw bestelling.</div>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="btn btn-blue" type="primary" @click="showSuccessPayment = false">
                Sluiten
                </el-button>
            </span>
        </template>
    </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import { useCartStore } from '../../stores/cart'
import { vMaska } from "maska"
import type { MaskOptions } from 'maska';
import http from "../../libs/axios"
import PaymentModal from './../PaymentModal.vue'

const cartStore = useCartStore()
const loadingCheckout = ref(false)
const discountCode : Ref<string> = ref('')
const openPaymentModal = ref(false)

const codeMaskInputOptions: MaskOptions = reactive({
    mask: "UUU-UUU",
    eager: true,
    tokens: {
        U: { pattern: /[a-zA-Z0-9]/, transform: (chr: string) => chr.toUpperCase() }
    }
})

const props = defineProps({
    'mollieRedirect': String,
    'userBalance': Number,
    'transactionCosts': Number,
})

const walletEnabled = computed(() => {
    return (props.userBalance ? (cartStore.total_price - cartStore.discount_price) <= props.userBalance : false)
})

onMounted(() => {
    if (props.mollieRedirect && props.mollieRedirect == 'true') {
        showSuccessPayment.value = true

        history.replaceState({}, document.title, window.location.href.split('?')[0])

        cartStore.emptyCart()
    }
})

const showSuccessPayment = ref(false)

function formatCurrency(amount: number) {
    return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(amount)
}

function handleClose () {
    cartStore.toggleCart(false)
}

function removeItem(index: number) {
    cartStore.removeItemFromCart(index)
}

function goToCheckout(paymentMethod: string) {
    loadingCheckout.value = true

    http.post('/api/producten/afrekenen', {
            cart: cartStore.items, 
            code: cartStore.discount_code,
            paymentMethod: paymentMethod,
        })
        .then(response => {
            window.location.assign(response.data)
            cartStore.toggleCart(false)
        })
        .catch(error => {
            loadingCheckout.value = false
            cartStore.toggleCart(false)
            return false
        })
}

function deleteDiscount() {
    cartStore.deleteDiscount(discountCode.value)
}

const applyDiscount = async() => {
    await cartStore.applyDiscount(discountCode.value)
}
</script>