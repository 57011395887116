<template>
    <div class="cards">
        <div class="account-card about-card">
            <div class="d-md-flex align-items-stretch">
                <div class="nav flex-column nav-pills" id="account-info-pills-tab" role="tablist" aria-orientation="vertical">
                    <h2 class="card-title">Info</h2>
                    <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Over jou</button>
                    <button class="nav-link" id="v-pills-education-tab" data-bs-toggle="pill" data-bs-target="#v-pills-education" type="button" role="tab" aria-controls="v-pills-education" aria-selected="true">Opleiding</button>
                    <button class="nav-link" id="v-pills-notifications-tab" data-bs-toggle="pill" data-bs-target="#v-pills-notifications" type="button" role="tab" aria-controls="v-pills-notifications" aria-selected="false">Meldingsvoorkeuren</button>
                    <!-- <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Accountvoorkeuren</button> -->
                </div>
                <div class="tab-content" id="account-info-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                        <div v-if="!editMode">
                            <div class="info-item">
                                <span class="icon"><i class="fa-solid fa-user"></i></span>
                                <div class="data">
                                    <span>{{ userForm.name }}</span>
                                    <span class="description">Naam</span>
                                </div>
                            </div>
                            <div class="info-item">
                                <span class="icon"><i class="fa-solid fa-location-dot"></i></span>
                                <div class="data">
                                    <span>{{ userForm.residence }}</span>
                                    <span class="description">Woonplaats</span>
                                </div>
                            </div>
                            <div class="info-item">
                                <span class="icon"><i class="fa-solid fa-phone"></i></span>
                                <div class="data">
                                    <span v-if="userForm.phone">06-{{ userForm.phone }}</span>
                                    <span v-else>Onbekend</span>
                                    <span class="description">Telefoonnummer</span>
                                </div>
                            </div>
                            <div class="info-item">
                                <span class="icon"><i class="fa-solid fa-cake-candles"></i></span>
                                <div class="data">
                                    <span>{{ formatUnixDate(userForm.birthdate) }}</span>
                                    <span class="description">Geboortedatum</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <el-form :model="userForm" :rules="rules" ref="userFormRef" label-width="120px" label-position="top" @submit.prevent="submitForm(userFormRef)">
                                <input type="hidden" v-model="userForm.id" disabled readonly>
                                <el-form-item label="Voornaam" prop="first_name">
                                    <el-input v-model="userForm.first_name"></el-input>
                                </el-form-item>
                                <el-form-item label="Tussenvoegsel" prop="middle_name">
                                    <el-input v-model="userForm.middle_name"></el-input>
                                </el-form-item>
                                <el-form-item label="Achternaam" prop="last_name">
                                    <el-input v-model="userForm.last_name"></el-input>
                                </el-form-item>
                                <el-form-item label="Woonplaats" prop="residence">
                                    <el-input v-model="userForm.residence"></el-input>
                                </el-form-item>
                                <el-form-item label="Telefoonnummer" prop="phone" class="with-prepend">
                                    <el-input v-model.number="userForm.phone" placeholder="12345678">
                                        <template #prepend>06-</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="Geboortedatum" prop="birthdate">
                                    <el-date-picker 
                                        v-model="birthdateComp"
                                        type="date"
                                        format="DD/MM/YYYY"
                                        value-format="x"
                                    >
                                    </el-date-picker>
                                </el-form-item>

                                <el-button class="btn btn-grey" @click="cancelEdit(userFormRef)">Annuleren</el-button>
                                <el-button class="btn btn-blue" @click="submitForm(userFormRef)">Opslaan</el-button>
                            </el-form>
                        </div>
                        
                        <el-button class="edit-button" @click="editMode = true" v-if="!editMode"><i class="fas fa-pencil"></i> <span class="text">Bewerken</span></el-button>
                    </div>
                    <div class="tab-pane fade" id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab" tabindex="1">
                        <div>
                            <div class="info-item study-item" v-for="(item, index) in userForm.studies">
                                <span class="icon"><i class="fa-solid fa-graduation-cap"></i></span>
                                <div class="data">
                                    <span class="title">Studeert aan <b>{{ item.institution }}</b> in {{ item.study_site }}</span>
                                    <span class="date">Vanaf {{ formatUnixDate(item.study_start, 'YYYY') }}</span>
                                    <span class="description">{{ item.study }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-notifications" role="tabpanel" aria-labelledby="v-pills-notifications-tab" tabindex="2">
                        <!-- Mailchimp -> kringmail -->
                        <div class="tab-header">
                            <h3 class="title">Meldingsvoorkeuren</h3>
                            <p class="description">Selecteer welke meldingen jij wilt ontvangen.</p>
                        </div>
                        <el-form label-position="top">
                            <div v-for="preference in userForm.all_preferences" :key="preference.identifier">
                                <el-form-item :prop="'preference_' + preference.identifier" :label="preference.name" label-position="top">
                                    <el-switch
                                        v-model="preference.preference"
                                        @change="updatePreference(preference)"
                                        active-text="Wel"
                                        inactive-text="Niet"
                                        :loading="loading"
                                    />
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <!-- <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabindex="3">
                        E-mail, 
                        Wachtwoord
                        account deactiveren
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, computed } from 'vue'
import type { PropType } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import http from "../../libs/axios"
import { useUserStore } from '../../stores/user'
import { formatUnixDate, removePhoneNumberPrefix } from "@F/js/helpers.ts"

import { Preference } from '../../types/preference'

interface Profile {
    id: number,
    name: string,
    first_name: string,
    middle_name: string | null,
    last_name: string,
    email: string,
    phone: number | null,
    birthdate: number,
    residence: string,
    studies: Array<Study>,
    password: string,
    password_confirmation: string,
    all_preferences: Array<Preference>
}

interface Study {
    id: number | null,
    study: string,
    institution: string,
    study_site: string,
    study_start: number,
}

const props = defineProps({
    'profile': Object as PropType<Profile>
})

const birthdateComp = computed({
    get() {
        return userForm.birthdate * 1000
    },
    set( newValue ) {
        if (newValue)
            userForm.birthdate = newValue / 1000
    }
})

const userStore = useUserStore()
const editMode = ref(false)
const loading = ref(false)
const selectedInput = ref('')
const userFormRef = ref<FormInstance>()
const userForm: Profile = reactive({
    id: 0,
    name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: null,
    birthdate: 0,
    residence: '',
    studies: [{
        id: 0,
        study: '',
        institution: '',
        study_site: '',
        study_start: 0
    }],
    password: '',
    password_confirmation: '',
    all_preferences: [],
    ...props.profile
})

const rules = reactive<FormRules>({
    first_name: [
        { required: true, message: "Vul jouw voornaam in", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
        { max: 255, message: "Voornaam te lang", trigger: "blur" },
    ],
    middle_name: [
        { min: 2, message: "Vul minstens 2 letters in", trigger: "blur" },
        { max: 20, message: "Vul maximaal 20 letters in", trigger: "blur" },
    ],
    last_name: [
        { required: true, message: "Vul jouw achternaam in", trigger: "blur"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
        { max: 255, message: "Achternaam te lang", trigger: "blur" },
    ],
    email: [
        { required: true, message: 'Vul jouw e-mailadres in', trigger: "blur" },
        { type: 'email', message: 'Vul een correct e-mailadres in', trigger: "blur" }
    ],
    phone: [
        { type: 'number', message: 'Vul alleen cijfers in', trigger: "blur" },
        { type: 'number', min: 10000000, max: 99999999, message: 'Vul de 8 cijfers na 06- in', trigger: "blur" },
    ],
    birthdate: [
        { required: true, message: 'Vul een geboortedatum in', trigger: "blur" },
        { type: 'date', message: 'Vul een correcte datum in', trigger: "blur" }
    ],
    residence: [
        { required: true, message: "Vul een woonplaats in", trigger: "change"},
        { min: 3, message: "Vul minstens 3 letters in", trigger: "blur" },
        { max: 255, message: "Woonplaats te lang", trigger: "blur" },
    ],
})

onMounted(() => {
    userForm.phone = removePhoneNumberPrefix(userForm.phone?.toString())
})

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            http.put('/api/account', userForm)
                .then(() => {
                    window.location.reload();
                })
                .catch(() => {return false})
        } else {
            return false;
        }
    });
}

const cancelEdit = (formEl: FormInstance | undefined) => {
  if (!formEl) return

  formEl.resetFields()
  editMode.value = false
}

const updatePreference = async (preference: Preference) => {
    loading.value = true

    await userStore.updatePreference(preference)

    loading.value = false
}

function addStudy() {
    const new_study = {
        id: null,
        study: '',
        institution: '',
        study_site: '',
        study_start: 0
    }

    if (userForm.studies) {
        userForm.studies.push(new_study)
    }
}

const querySuggestions = (queryString: string, cb: (arg: any) => void) => {
    http.post('/api/register/querySuggestions/' + selectedInput.value, {'query': queryString})
    .then(response => {
        cb(response);
    })
    .catch(() => {cb([])});
}
</script>