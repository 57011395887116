import { defineStore } from 'pinia'
import http from "../libs/axios"

import { type Preference } from '../types/preference'

interface State {
    preferences: Array<{ [key: string]: boolean }>,
}

export const useUserStore = defineStore('user', {
    state: (): State => {
        return {
            preferences: [],
        }
    },
    actions: {
        async fetchPreferences() {
            await http.get('/api/account/voorkeuren')
                .then((response) => {
                    this.preferences = response
                }).catch(() => {
                    this.preferences = []
                    return false
                })
        },
        async updatePreference(preference: Preference) {
            await http.put('/api/account/voorkeuren', {
                preference: preference
            })
                .then(() => {
                    this.fetchPreferences()
                    return true
                }).catch(() => {
                    return false
                })
        },
        resetPreferences() {
            this.$reset()
        }
    },
    persist: true,
})